const elements = document.querySelectorAll('.show')

elements.forEach(el => {
    el.style.opacity = '0'
    el.style.transform = 'translateY(40%)'
    el.style.transition = '1s ease '
})
elements.forEach(el => {
    const data = el.getBoundingClientRect()
    console.log(data.top, window.pageYOffset)
    if (window.pageYOffset > data.top) {
        el.style.opacity = '1'
        el.style.transform = 'translateY(0%)'
    }
})

const check = e => {
    elements.forEach(el => {
        const data = el.getBoundingClientRect()
        const windowHeight = window.innerHeight
        const top = data.top

        if (top < windowHeight) {
            el.style.opacity = '1'
            el.style.transform = 'translateY(0%)'
        } else {
            el.style.opacity = '0'
            el.style.transform = 'translateY(40%)'
        }
    })
}
document.addEventListener('scroll', check)
